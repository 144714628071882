export default [
  {
    title: 'Dashboard',
    route: 'dashboard',
    icon: 'PieChartIcon',
    children: [
      {
        title: 'Quote Price',
        route: 'quote-price',
        icon: 'TagIcon',
      },
      {
        title: 'Order Analytic',
        route: 'order-analytic',
        icon: 'BarChartIcon',
      },
      {
        title: 'Order Log',
        route: 'order-log',
        icon: 'TrelloIcon',
      },
    ],
  },
  // {
  //   title: "My Task",
  //   route: "my-task",
  //   icon: "ListIcon",
  // },
  {
    title: 'Outlet Order',
    route: 'Outlet-order',
    icon: 'BoxIcon',
    children: [
      {
        title: 'Create Order',
        route: 'Outlet-order',
        icon: 'BoxIcon',
      },
      {
        title: 'Incoming Order',
        route: 'incoming-order-Outlet',
        icon: 'LogInIcon',
      },
      {
        title: 'My Order',
        route: 'outgoing-order-Outlet',
        icon: 'LogOutIcon',
      },
    ],
  },
  {
    title: 'Singapore Order',
    route: 'singapore-order',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Create Order',
        route: 'singapore-order-singapore',
        icon: 'BoxIcon',
      },
      {
        title: 'Incoming Order',
        route: 'incoming-order-singapore',
        icon: 'LogInIcon',
      },
      {
        title: 'My Order',
        route: 'outgoing-order',
        icon: 'LogOutIcon',
      },
    ],
  },
  {
    title: 'Bulk Order',
    route: 'bulk-order',
    icon: 'GridIcon',
  },
  {
    title: 'DHL Order',
    route: 'dhl-order',
    icon: 'TruckIcon',
  },
  {
    title: 'B2B Order',
    route: 'b2b-order',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Orders',
        route: 'incoming-order-b2b',
        icon: 'LogInIcon',
      },
      {
        title: 'My Deliveries',
        route: 'outgoing-order-b2b',
        icon: 'TruckIcon',
      },
    ],
  },
  {
    title: 'Track & Trace',
    route: 'track-and-trace',
    icon: 'MapIcon',
  },
  {
    title: 'Postal Coverage',
    route: 'postal-coverage',
    icon: 'PieChartIcon',
  },
  {
    title: 'Transaction',
    route: 'transaction',
    icon: 'MapPinIcon',
  },
  {
    title: 'Report',
    route: 'report',
    icon: 'ClipboardIcon',
  },
  {
    title: 'Phone Book',
    route: 'phone-book',
    icon: 'PhoneCallIcon',
  },
  {
    title: 'Log Points',
    route: 'log-points',
    icon: 'SearchIcon',
  },
];
